/*
 * Created by @tranphuquy19 on 08/12/2020
 * @author: tranphuquy19@gmail.com
 */

import { homeState } from '../states/HomeState';

export const UT_FETCH_DATA = 'UT_FETCH_DATA';
export const UT_ADD_SLIDER_IMAGE = 'UT_ADD_SLIDER_IMAGE';
export const UT_ADD_SLIDER_PRODUCT = 'UT_ADD_SLIDER_PRODUCT';
export const UT_MOVE_TO_TOP_SLIDER_IMAGE = 'UT_MOVE_TO_TOP_SLIDER_IMAGE';
export const UT_MOVE_TO_TOP_SLIDER_PRODUCT = 'UT_MOVE_TO_TOP_SLIDER_PRODUCT';
export const UT_EDIT_ITEM_PRODUCT_AT_INDEX = 'UT_EDIT_ITEM_PRODUCT_AT_INDEX';
export const UT_REMOVE_SLIDER_PRODUCT = 'UT_REMOVE_SLIDER_PRODUCT';
export const UT_EDIT_ITEM_IMAGE_AT_INDEX = 'UT_EDIT_ITEM_IMAGE_AT_INDEX';
export const UT_REMOVE_SLIDER_IMAGE = 'UT_REMOVE_SLIDER_IMAGE';
export const UT_EDIT_TOP_PRODUCTS = 'UT_EDIT_TOP_PRODUCTS';
export const UT_EDIT_OFFICES_INFO = 'UT_EDIT_OFFICES_INFO';
export const UT_EDIT_PRODUCT_NAVBAR = 'UT_EDIT_PRODUCT_NAVBAR';
export const UT_EDIT_TOP_NEWS = 'UT_EDIT_TOP_NEWS';
export const UT_EDIT_TOP_PROJECTS = 'UT_EDIT_TOP_PROJECTS';
export const UT_EDIT_TOP_SOLUTIONS = 'UT_EDIT_TOP_SOLUTIONS';
export const UT_EDIT_SOCIAL_LINKS = 'UT_EDIT_GG_MAPS_LINK';
export const UT_EDIT_OUR_STORY = 'UT_EDIT_OUR_STORY';
export const UT_EDIT_HEADER_IMAGES = 'UT_EDIT_HEADER_IMAGES';
export const UT_ABOUT_US = 'UT_ABOUT_US';
export const UT_OUR_VALUES = 'UT_OUR_VALUES';
export const UT_OTHER_VALUES = 'UT_OTHER_VALUES';
// export const UT_TESTIMONIALS = 'UT_TESTIMONIALS';
export const UT_EDIT_TESTIMONIAL_AT_INDEX = 'UT_EDIT_TESTIMONIAL_AT_INDEX';
export const UT_REMOVE_HAPPY_CLIENT_AT_INDEX =
    'UT_REMOVE_HAPPY_CLIENT_AT_INDEX';
export const UT_REMOVE_TESTIMONIAL_AT_INDEX = 'UT_REMOVE_TESTIMONIAL_AT_INDEX';
export const UT_ADD_TESTIMONIAL = 'UT_ADD_TESTIMONIAL';
export const UT_ADD_HAPPY_CLIENT = 'UT_ADD_HAPPY_CLIENT';
export const UT_EDIT_HAPPY_CLIENT_AT_INDEX = 'UT_EDIT_HAPPY_CLIENT_AT_INDEX';
export const UT_TOGGLE_OUR_VALUES = 'UT_TOGGLE_OUR_VALUES';
export const UT_TOGGLE_METRICS = 'UT_TOGGLE_METRICS';
export const UT_TOGGLE_SHOW_HAPPY_CLIENTS = 'UT_TOGGLE_SHOW_HAPPY_CLIENTS';
export const UT_TOGGLE_TESTIMONIALS = 'UT_TOGGLE_TESTIMONIALS';
export const UT_TOGGLE_ABOUT_US = 'UT_TOGGER_ABOUT_US';
export const UT_TOGGLE_CONTACT_US = 'UT_TOGGLE_CONTACT_US';

export const utHomeReducer = (state, action) => {
    switch (action.type) {
        case UT_TOGGLE_OUR_VALUES: {
            return {
                ...state,
                isShowOurValues: !state.isShowOurValues,
            }
        }
        case UT_TOGGLE_METRICS: {
            return {
                ...state,
                isShowMetrics: !state.isShowMetrics,
            }
        }
        case UT_TOGGLE_SHOW_HAPPY_CLIENTS: {
            return {
                ...state,
                isShowHappyClients: !state.isShowHappyClients,
            }
        }
        case UT_TOGGLE_TESTIMONIALS: {
            return {
                ...state,
                isShowTestimonials: !state.isShowTestimonials,
            }
        }
        case UT_TOGGLE_ABOUT_US: {
            return {
                ...state,
                isShowAboutUs: !state.isShowAboutUs,
            }
        }
        case UT_TOGGLE_CONTACT_US: {
            return {
                ...state,
                isShowContactUs: !state.isShowContactUs,
            }
        }
        case UT_FETCH_DATA:
            return { ...action.payload };
        case UT_ADD_SLIDER_IMAGE:
            return {
                ...state,
                sliderImages: [
                    ...state.sliderImages,
                    {
                        head1: '',
                        head2: '',
                        link: '',
                    },
                ],
            };
        case UT_ADD_TESTIMONIAL:
            return {
                ...state,
                testimonials: [
                    ...state.testimonials,
                    {
                        name: '',
                        position: '',
                        content: '',
                        avatar: '',
                    },
                ],
            };
        case UT_ADD_HAPPY_CLIENT:
            return {
                ...state,
                happyClients: [
                    ...state.happyClients,
                    {
                        name: '',
                        logo: '',
                        companyUrl: '#',
                    },
                ],
            };
        case UT_ADD_SLIDER_PRODUCT:
            return {
                ...state,
                sliderProducts: [...state.sliderProducts, { code: '' }],
            };
        case UT_MOVE_TO_TOP_SLIDER_IMAGE:
            const {
                payload: { index: index4 },
            } = action;
            return {
                ...state,
                sliderImages: [
                    state.sliderImages[index4],
                    ...state.sliderImages.filter((e, i) => i !== index4),
                ],
            };
        case UT_MOVE_TO_TOP_SLIDER_PRODUCT:
            const {
                payload: { index: index3 },
            } = action;
            return {
                ...state,
                sliderProducts: [
                    state.sliderProducts[index3],
                    ...state.sliderProducts.filter((e, i) => i !== index3),
                ],
            };
        case UT_EDIT_ITEM_PRODUCT_AT_INDEX:
            const {
                payload: { index, value, key },
            } = action;
            return {
                ...state,
                sliderProducts: state.sliderProducts.map((e, i) => {
                    if (i === index) {
                        e[key] = value;
                    }
                    return e;
                }),
            };
        case UT_REMOVE_SLIDER_PRODUCT:
            const {
                payload: { index: index2 },
            } = action;
            return {
                ...state,
                sliderProducts: state.sliderProducts.filter(
                    (e, i) => i !== index2,
                ),
            };
        case UT_REMOVE_SLIDER_IMAGE:
            const {
                payload: { index: index5 },
            } = action;
            return {
                ...state,
                sliderImages: state.sliderImages.filter((e, i) => i !== index5),
            };
        case UT_REMOVE_HAPPY_CLIENT_AT_INDEX:
            const {
                payload: { index: index8 },
            } = action;
            return {
                ...state,
                happyClients: state.happyClients.filter((e, i) => i !== index8),
            };
        case UT_REMOVE_TESTIMONIAL_AT_INDEX:
            const {
                payload: { index: index9 },
            } = action;
            console.log('index9', index9);
            return {
                ...state,
                testimonials: state.testimonials.filter((e, i) => i !== index9),
            };
        case UT_EDIT_ITEM_IMAGE_AT_INDEX:
            const {
                payload: { index: index1, value: value1, key: key1 },
            } = action;
            return {
                ...state,
                sliderImages: state.sliderImages.map((e, i) => {
                    if (i === index1) {
                        e[key1] = value1;
                    }
                    return e;
                }),
            };
        case UT_EDIT_HAPPY_CLIENT_AT_INDEX:
            const {
                payload: { index: index7, key: key7, value: value7 },
            } = action;
            return {
                ...state,
                happyClients: state.happyClients.map((e, i) => {
                    if (i === index7) {
                        e[key7] = value7;
                    }
                    return e;
                }),
            };
        case UT_EDIT_TESTIMONIAL_AT_INDEX:
            const {
                payload: { index: index6, key: key6, value: value6 },
            } = action;
            return {
                ...state,
                testimonials: state.testimonials.map((e, i) => {
                    if (i === index6) {
                        e[key6] = value6;
                    }
                    return e;
                }),
            };
        case UT_EDIT_TOP_PRODUCTS:
            const { payload: _topProducts } = action;
            return {
                ...state,
                topProducts: _topProducts,
            };
        case UT_EDIT_TOP_PROJECTS:
            const { payload: _topProjects } = action;
            return {
                ...state,
                topProjects: _topProjects,
            };
        case UT_EDIT_TOP_SOLUTIONS:
            const { payload: _topSolutions } = action;
            return {
                ...state,
                topSolutions: _topSolutions,
            };
        case UT_EDIT_TOP_NEWS:
            const { payload: _topNews } = action;
            return {
                ...state,
                topNews: _topNews,
            };
        case UT_EDIT_OFFICES_INFO:
            const { nameOffice, valueOffice } = action.payload;
            if (nameOffice === 'tags') {
                let _tags = { ...state.tags[0], content: valueOffice };
                return {
                    ...state,
                    tags: [_tags],
                };
            }
            if (
                nameOffice === 'homeDescription' ||
                nameOffice === 'homeTitle'
            ) {
                return {
                    ...state,
                    [nameOffice]: valueOffice,
                };
            }
            let a = { ...state.offices[0], [nameOffice]: valueOffice };
            return {
                ...state,
                offices: [a],
            };
        case UT_EDIT_PRODUCT_NAVBAR:
            const { payload: productsNavbar } = action;
            return {
                ...state,
                productsNavbar,
            };
        case UT_EDIT_SOCIAL_LINKS:
            const {
                payload: { name: linkName, value: linkValue },
            } = action;
            return {
                ...state,
                [linkName]: linkValue,
            };
        case UT_EDIT_OUR_STORY: {
            const { payload } = action;
            return {
                ...state,
                ourStory: payload,
            };
        }
        case UT_ABOUT_US: {
            const { payload } = action;
            return {
                ...state,
                aboutUs: payload,
            };
        }
        case UT_OUR_VALUES: {
            const { payload } = action;
            return {
                ...state,
                ourValues: payload,
            };
        }
        case UT_OTHER_VALUES: {
            const { payload } = action;
            return {
                ...state,
                otherValues: payload,
            };
        }
        // case UT_TESTIMONIALS: {
        //     const { payload } = action;
        //     return {
        //         ...state,
        //         testimonials: payload
        //     }
        // }
        case UT_EDIT_HEADER_IMAGES: {
            const {
                payload: {
                    image: { fileUrl, originalName },
                    page,
                },
            } = action;
            return {
                ...state,
                headerImages: {
                    ...state.headerImages,
                    [page]: { fileUrl, originalName },
                },
            };
        }
        default:
            return homeState;
    }
};
